import { CalculateFontSize, CalculateFromWidth } from "styled/base/SizeFunctions";
import { css } from "styled-components";

export const LegalNoticeTitleFont = css`
  ${CalculateFontSize("font-size", { mobile: 8, tablet: 7, desktop: 9 })}
  ${CalculateFontSize("line-height", { mobile: 9, tablet: 9, desktop: 9 })}
  ${CalculateFontSize("letter-spacing", { mobile: -0.5, tablet: -0.5, desktop: -0.5 })}
  ${CalculateFromWidth("word-spacing", { mobile: 2, tablet: 2, desktop: 2 })}
  ${({ theme: { fonts } }) => fonts.demiBold}
`;

export const LegalNoticeFont = css`
  ${CalculateFontSize("font-size", { mobile: 0.6, tablet: 0.75, desktop: 0.9 })}
  ${CalculateFontSize("line-height", { mobile: 1.5, tablet: 1.5, desktop: 1.5 })}
  ${({ theme: { fonts } }) => fonts.light}
`;

export const LegalNoticePointFont = css`
  text-align: justify;
`;
