import { GatsbyImage } from "gatsby-plugin-image";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { LegalNoticeFont, LegalNoticeTitleFont } from "styled/common/font-styles/LegalNoticeFonts";
import { DisableSelection, FillSpaceMixin } from "styled/common/Utils";
import styled from "styled-components";

export const LegalNoticeWrapper = styled.section`
  height: fit-content;
  width: 80%;
  padding-left: 10%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding-right: 10%;
  ${CalculateFromHeight("margin-top", { mobile: 15, tablet: 15, desktop: 15 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 25, tablet: 25, desktop: 25 })}
`;
export const LegalNoticeContent = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
`;
export const LegalNoticeTitle = styled.h1`
  ${LegalNoticeTitleFont}
  justify-content: center;
  width: 100%;
  margin-left: 0;
  ${CalculateFromHeight("margin-bottom", { mobile: 10, tablet: 10, desktop: 10 })}
`;
export const LegalNoticeSubtitle = styled.div`
  ${LegalNoticeFont}
  justify-content: center;
  width: 100%;
  margin-left: 0;
  ${CalculateFromHeight("margin-top", { mobile: 3, tablet: 3, desktop: 3 })}
`;
export const LegalNoticeInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${CalculateFromHeight("height", { mobile: 60, tablet: 40, desktop: 30 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 5, desktop: 5 })}
`;
export const LegalNoticeInfoLeft = styled.div`
  width: 50%;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 2;
  ${CalculateFromWidth("margin-right", { mobile: 10, tablet: 10, desktop: 10 })}
`;
export const LegalNoticeInfoLeftRatio = styled.div`
  padding-top: 42%;
  height: 0;
  top: 0;
  position: relative;
  left: 0;
  width: 100%;
  object-fit: contain;
`;
export const LegalNoticeInfoMkdnisLogo = styled(GatsbyImage)`
  ${FillSpaceMixin}
  ${DisableSelection}
  position: absolute !important;
  top: 0;
  object-fit: contain;
`;
export const LegalNoticeInfoRight = styled.p`
  right: 0;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  flex-shrink: 10;
`;
