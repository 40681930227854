import React, { useRef } from "react";

import Layout from "components/layout/Layout/Layout";
import {
  LegalNoticeContent,
  LegalNoticeInfoLeft,
  LegalNoticeInfoLeftRatio,
  LegalNoticeInfoMkdnisLogo,
  LegalNoticeInfoRight,
  LegalNoticeInfoWrapper,
  LegalNoticeSubtitle,
  LegalNoticeTitle,
  LegalNoticeWrapper
} from "components/pages/legal-notice/LegalNotice.styled";
import { useImageDatabaseContext } from "contexts/ImageContext";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import gsap, { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";
import Translate from "utils/translation/translation";

const LegalNotice = ({ location }: PageProps) => {
  const policyTitle = useRef<HTMLHeadingElement>(null);
  const policySubtitle = useRef(null);
  const [imageDatabase] = useImageDatabaseContext();

  useFirstRenderEffect(() => {
    gsap.from(policyTitle.current, {
      opacity: 0,
      x: 50,
      delay: 0.25,
      duration: 0.5,
      ease: Circ.easeOut
    });
    gsap.from(policySubtitle.current, {
      opacity: 0,
      x: -50,
      delay: 0.5,
      duration: 0.5,
      ease: Circ.easeOut
    });
  }, []);

  return (
    <>
      <GatsbySeo {...GetSeoData("LegalNotice")} />
      <Layout location={location}>
        <LegalNoticeWrapper>
          <LegalNoticeContent>
            <LegalNoticeTitle ref={policyTitle}>{Translate("Legal_Title")}</LegalNoticeTitle>
            <LegalNoticeSubtitle>
              <LegalNoticeInfoWrapper>
                <LegalNoticeInfoLeft>
                  <LegalNoticeInfoLeftRatio>
                    <LegalNoticeInfoMkdnisLogo
                      draggable={false}
                      image={imageDatabase.MKDNiS_ENG.childImageSharp.gatsbyImageData}
                      alt={"MKDNiS logo"}
                    />
                  </LegalNoticeInfoLeftRatio>
                </LegalNoticeInfoLeft>
                <LegalNoticeInfoRight>{Translate("Legal_Narru_Info_ENG")}</LegalNoticeInfoRight>
              </LegalNoticeInfoWrapper>
              <LegalNoticeInfoWrapper>
                <LegalNoticeInfoLeft>
                  <LegalNoticeInfoLeftRatio>
                    <LegalNoticeInfoMkdnisLogo
                      draggable={false}
                      image={imageDatabase.MKDNiS_PL.childImageSharp.gatsbyImageData}
                      alt={"MKDNiS logo"}
                    />
                  </LegalNoticeInfoLeftRatio>
                </LegalNoticeInfoLeft>
                <LegalNoticeInfoRight>{Translate("Legal_Narru_Info_PL")}</LegalNoticeInfoRight>
              </LegalNoticeInfoWrapper>
            </LegalNoticeSubtitle>
          </LegalNoticeContent>
        </LegalNoticeWrapper>
      </Layout>
    </>
  );
};

export default LegalNotice;
